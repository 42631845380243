import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import PostLink from "../../components/post-link"

const IndexPage = ({
  data,
  pageContext,
  location,
}) => {
  
  const Posts = data.subPages.edges.map(edge => {
    const path = "/" + edge.node.slug;
    return <li key={edge.node.slug}><Link to={path}>{edge.node.frontmatter.title}</Link></li>
  })


  return (<Layout >
    <SEO title={pageContext.title}  pathname={location.pathname} />
    <h1 className="title is-2">The Illuminated Manuscript</h1>
    <h2 className="subtitle is-3">A bunch of bright ideas for playing with electronics</h2>
    <section className="section">
    <div className="columns">
    <div className="column is-two-thirds">
    <div className="content">
    <p>I started out trying to write a short tour of some LED driver chips that I've used and found handy to solve problems.</p>
    <p>This grew outwards from there.  These are working notes, potentially incorrect and inaccurate and out-of-date, written by someone who is doing this for fun and not in pursuit of the electronics trade.</p>
    <p>At least a third of this is where I ended up having some chat with one of my LED-electronics-oriented friends and realized I should actually write this down.</p>
    <p>You shouldn't feel obligated to read the whole thing, I've tried to link forwards and backwards so you can just pick out the juicy content that interests you and ignore the rest.</p>
    <h2>You may be able to avoid this problem altogether!</h2>
    <p>You can make everything with intelligent LED strips.</p>
    <p>There's a bunch of pre-made switching power supply modules floating around and they are inexpensive and nice.  <a href="https://www.pololu.com/category/136/voltage-regulators">Pololu has a bunch of handy modules</a> and there's whole categories at the usual merchants.  There's prototyping-friendly ones that you can just plug into a breadboard as well as surface-mount modules that can make a lot of problems go away.  And they make some <a href="https://www.pololu.com/category/121/pololu-power-switches">nice power switch modules</a> as well.</p>
    <p>You don't need to do this.  Until you do.</p>
    <p>At least for me, I started seeing cases where I said &quot;Wouldn't it be nice if there was a little module to do...&quot; and realized that there was a chip that implemented this but no reasonable module that I could just plug into a project.</p>
    <p>And, again, sometimes you just want a single board with nothing hanging out.</p>
    <h2>I am not an electrical engineer!</h2>
    <p>I'm going to do a level-set here, because all of the electronics I'm describing are really awesome things for making neat bits of hardware, but I want to place where I am on a scale so you won't get mad at me if you try to build one and start your breadboard on fire or something.</p>
    <p><strong>Because, yeah, you can set your project on fire!</strong></p>
    <p>In college, I studied Math and Computer Science.  This meant that I never had a proper college-level electronics lab class where you spent an entire term hunched over a breadboard making op-amps generate smoke.  I'm just this guy who programs Arduinos and mostly understands how to use a transistor in a circuit sometimes and does this all for fun and I've got pretty darn decent soldering skills most of the time.</p>
    <p>So, yes, this is written for a midling skill level and aspiration.  I am at the same time intentionally ignoring all sorts of things that are necessary for a person to deeply grasp a subject area because I am not an electrical engineer and don't have time to become one and I am treading blindly into the woods of power electronics.</p>
    <p>Nor am I making the pretense of writing an actual textbook!  I'm going to cover the interesting bits and maybe if you get really deep somewhere, you might want to whip a real textbook out, but I don't want to give you problems and exercises.</p>
    </div>
    </div>
    <div className="column is-one-thirds">
    <menu class="menu">
    <p class="menu-label">Table of Contents</p>
    <ul class="menu-list">
    {Posts}
    </ul>
    </menu>
    </div>
    </div>
    </section>
  </Layout>);
}

export default IndexPage

export const pageQuery = graphql`
  {
    subPages: allMdx(
      filter: {slug: {regex: "/make/the_illuminated_manuscript.*/"}, frontmatter: {draft: {ne: true}}}
      sort: {fields: frontmatter___ordering, order: ASC}
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            ordering
            title
          }
        }
      }
    }
  }

`


export const frontmatter = {
  title: "The Illuminated Manuscript",
  date: "2023-06-24T15:00:00-07"
}